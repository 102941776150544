import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';

import ConfigurationListTable from '../../../components/AdditionalComponents/ConfigurationListTable';
import { APPLICATION_CONFIG_COLUMNS } from '../../../constants/TableColumns/Admin/ApplicationConfigColumns';
import { ADMIN_APPLICATION_MGMNT_TEXT } from '../../../constants/breadCrumbTextConstants';
import { AdminMainBreadCrumbWrapper, AdminMainLayoutWrapper } from '../../../assets/styles/commonStyled';
import routeConstants from '../../../constants/routeConstants';
import { addApplicationDetails, deleteApplicationConfig, getAllExceptionCategory, getAllExceptionSeverity, getAllExceptionType, getApplicationDetails, getApplicationSectorList } from '../../../redux/actions/Admin/applicationConfigActions';
import { useDispatch, useSelector } from 'react-redux';
import { getLogAndExceptionTableMetaData } from '../../../redux/actions/Admin/tableConfigActions';
import { CONFIG_TABLE_SEARCH_FILTER_KEY } from '../../../constants/KeyLabels/commonKeyConstants';
import { getCachedTableFilterData, resetLastSelectedApplicationDetailTab, setKeyDataInLocalStorage } from '../../../helpers/localStorageHelper';
import AddConfigDrawer from '../../../components/AdditionalComponents/Drawer/AddConfigDrawer';
import ApplicationConfigForm from '../../../components/AdditionalComponents/UpsertForms/Admin/ApplicationConfig';
import textConstants from '../../../constants/textConstants';
import { checkIfExistsInArray } from '../../../helpers/commonHelper';
import { errorNotification } from '../../../components/common/Notification';

const ApplicationsConfig: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const globalApplicationState = useSelector((state: any) => state?.applicationConfig);
  const applicationConfigList = useSelector((state: any) => state?.applicationConfig?.applicationConfigList);
  const tableConfigList = useSelector((state: any) => state?.tableConfig?.tableConfigList);
  const allCategoryList = useSelector((state: any) => state?.listConfig?.categories);
  const allSeverityList = useSelector((state: any) => state?.listConfig?.severities);
  const allTypeList = useSelector((state: any) => state?.listConfig?.types);

  const [isAddDrawerVisible, setAddDrawerVisibility] = useState(false);

  useEffect(() => {
    if (globalApplicationState?.applicationSectorList?.length === 0 && !globalApplicationState?.isApplicationSectorListLoading) {
      dispatch(getApplicationSectorList());
    }
    if (allCategoryList?.length === 0) {
      dispatch(getAllExceptionCategory());
    }
    if (allSeverityList?.length === 0) {
      dispatch(getAllExceptionSeverity());
    }
    if (allTypeList?.length === 0) {
      dispatch(getAllExceptionType());
    }
    if (tableConfigList.length === 0) {
      dispatch(getLogAndExceptionTableMetaData());
    }
    resetLastSelectedApplicationDetailTab();
    setKeyDataInLocalStorage(CONFIG_TABLE_SEARCH_FILTER_KEY, {
      applicationConfig: getCachedTableFilterData('applicationConfig')
    });
  }, []);

  const showDetailPageHandler = (rowData: any, isEditMode: boolean): void => {
    history.push({
      pathname: routeConstants.ADMIN_APPLICATION_DETAIL,
      state: { applicationDetailData: rowData, isEditMode }
    });
  }

  const toggleAddDrawerVisibility = (isOpen: boolean): void => {
    setAddDrawerVisibility(isOpen);
  }

  const onSaveHandler = (configData: any, isClosable?: boolean): void => {
    if (!checkIfExistsInArray(applicationConfigList, configData, 'applicationName')) {
      dispatch(addApplicationDetails(configData, isClosable ? toggleAddDrawerVisibility(false) : null, true));
    } else {
      errorNotification(textConstants.APPLICATION_CONFIG_ALREADY_EXIST_MESSAGE);
    }
  }
  return (
    <AdminMainLayoutWrapper data-testid={'applicationConfigList'}>
      <AdminMainBreadCrumbWrapper>
        {ADMIN_APPLICATION_MGMNT_TEXT}
      </AdminMainBreadCrumbWrapper>
      <ConfigurationListTable
        columnDefs={APPLICATION_CONFIG_COLUMNS}
        gridName='Application Config'
        getConfigList={getApplicationDetails}
        onConfigDelete={deleteApplicationConfig}
        listName='applicationConfig'
        hasCreate
        hasRowAction
        rowData={applicationConfigList}
        onCreate={() => setAddDrawerVisibility(true)}
        onEdit={(rowData: any) => showDetailPageHandler(rowData, true)}
        onRowDoubleClicked={(params: { data: any }) => showDetailPageHandler(params?.data, false)}
        hasAuditSummary
      />
      {
        isAddDrawerVisible &&
        <AddConfigDrawer
          isOpen={isAddDrawerVisible}
          dataTestId='test-id-application-config-add-drawer'
          title={textConstants.APPLICATION_ADD_DRAWER_TITLE}
          onCancel={() => setAddDrawerVisibility(false)}
        >
          <ApplicationConfigForm
            applicationDetailData={null}
            isEditable={false}
            onSave={onSaveHandler}
            onCancel={() => setAddDrawerVisibility(false)}
          />
        </AddConfigDrawer>
      }
    </AdminMainLayoutWrapper>
  );
};

export default ApplicationsConfig;
